var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"aboutAdjuz"}},[_c('headerbom',{attrs:{"hdtitle":'关于巨掌'}}),_vm._m(0),_vm._m(1),_c('p',{staticClass:"adjuz_cont"},[_vm._v("巨掌成立于2014年，以AI+大数据智能算法为广告主提供精准移动效果营销服务，是国内头部的平台型解决方案服务商。管理团队深耕行业多年，致力于打造多方共赢的健康可持续发展生态体系。")]),_c('p',{staticClass:"adjuz_cont"},[_vm._v(" 公司凭借庞大自有流量、优质的品牌广告资源、强大的移动广告技术和丰富的客户资源，为广告主提供优质的国内外资源和高转化率推广服务。我们的具体业务包括iOS全案优化服务、ASA投放优化服务、互动广告营销、信息流媒体合作等一站式无线营销解决方案，使广告主获得价值最大化。")]),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"home-banner-kuan"},[_c('div',{staticClass:"ad_video_box"},[_c('div',{staticClass:"ad_video home-banner"},[_c('video-player',{ref:"videoPlayer",attrs:{"options":_vm.playerOptions}})],1)])]),_vm._m(5),_c('div',{staticClass:"adjuz_big"},[_c('img',{staticClass:"top_img",attrs:{"src":require("../img/about8.png"),"alt":""}}),_c('ul',_vm._l((_vm.bigeventlist),function(ele,index){return _c('li',{key:index,class:{left: index % 2 == 0,right: index % 2 == 1}},[(index % 2 == 0)?_c('img',{staticClass:"left_img",attrs:{"src":require("../img/about9.png"),"alt":""}}):_vm._e(),(index % 2 == 1)?_c('img',{staticClass:"right_img",attrs:{"src":require("../img/about10.png"),"alt":""}}):_vm._e(),_c('h1',[_vm._v(_vm._s(ele.date))]),_c('p',[_vm._v(_vm._s(ele.conts))])])}),0)]),_c('footerbom')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("../img/about1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h1_title"},[_c('h1',[_vm._v("关于巨掌数据")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h1_title"},[_c('h1',[_vm._v("巨掌数据认为")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"adjuz_sen"},[_c('img',{attrs:{"src":require("../img/about5.png"),"alt":""}}),_c('p',[_vm._v(" 只有专注于内在的修炼，务实地去钻研探究，"),_c('br'),_vm._v(" 才会开启更多营销可能。"),_c('br'),_vm._v(" 面对现在行业浮躁与不安 ，"),_c('br'),_vm._v(" 我们应让营销回归本质。"),_c('br'),_vm._v(" 让每一次营销都产生应有的真实效果，"),_c('br'),_vm._v(" 并为客户创造实际价值让客户真正感受到营销的魅力所在。 "),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h1_title"},[_c('h1',[_vm._v("巨掌团队")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h1_title"},[_c('h1',[_vm._v("巨掌发展大事件")])])
}]

export { render, staticRenderFns }